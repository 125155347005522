<template>
    <modal @save="store">
        <template v-slot:header>{{ breed.id ? 'Ras wijzigen' : 'Nieuw ras' }}</template>
        <div>
            <span class="styled-label">Ras naam</span>
            <input class="styled-input" type="text" name="name" v-model="breed.name">
            <br/>
            <br/>
            <span class="styled-label">Type</span>
            <select class="styled-select" v-model="breed.typeId">
                <option v-for="typeModel of types" :key="typeModel.id" :value="typeModel.id">{{ typeModel.translate('name') }}</option>
            </select>
            <br/>
            <br/>
            <span class="styled-label">Fracties</span>
            <MultiSelect
                v-model="breed.fractionsIds"
                :options="fractions.map(f => ({label: f.milimeters.toString(), value: f.id})).all()"
            />
            <br/>
            <br/>
        </div>
    </modal>
</template>

<script setup>
    import breedService from '@/services/http/breed-service';
    import MultiSelect from '@/components/Form/MultiSelect.vue';
    import { useToast } from 'vue-toast-notification';

    const props = defineProps(['breed', 'types', 'fractions'])
    const emit = defineEmits(['breedStored'])

    const store = function(){
        if (props.breed.id) {
            breedService.edit(props.breed.id, {
                name: props.breed.name,
                fraction_ids: props.breed.fractionsIds.filter(f => f !== null),
                type_id: props.breed.typeId
            }, {include: ['fractions', 'type']}).then((breed) => {

                emit('breedStored', breed);
                useToast().success('Ras gewijzigd');
                document.querySelector('body').classList = '';

            });
        } else {
            breedService.create({
                name: props.breed.name,
                fraction_ids: props.breed.fractionsIds,
                type_id: props.breed.typeId
            }, {include: ['fractions', 'type']}).then((breed) => {

                emit('breedStored', breed);
                useToast().success('Ras aangemaakt');
                document.querySelector('body').classList = '';

            });
        }
        emit('breedStored', null);
    }
</script>
