<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div id="tasks">
                    <div class="page-header">
                        <h1>Rassen</h1>
                        <fab-button @click.native="openCreateBreedModal" icon="plus"></fab-button>
                    </div>

                    <div class="widget-holder">
                        <table class="styled-table">
                            <tr class="styled-table-row" v-for="(breed, index) of breeds" :key="index">
                                <td>{{ breed.name }}</td>
                                <td class="text-left">{{ breed.type.translate('name') }}</td>
                                <td class="text-left">{{ breed.fractions.count() ? breed.fractions.implode('milimeters', ', ') : '-' }}</td>
                                <td class="styled-table-row-icons">
                                    <a @click="openEditBreedModal(breed)"><icon name="pencil" /></a>
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>
            </div>
        </div>

        <CreateEditBreedModal
            v-if="createEditFractionsModal"
            :breed="createEditFractionsModal"
            :types="types" :fractions="fractions"
            @close="createEditFractionsModal = false"
            @breedStored="breedStored"
        />
    </div>
</template>

<script>

    import fractionService from '../../services/http/fraction-service';
    import typeService from '../../services/http/type-service';
    import breedService from '@/services/http/breed-service';
    import { collect } from "collect.js";
    import MultiSelect from '../../components/Form/MultiSelect.vue';
    import CreateEditBreedModal from './CreateEditBreedModal.vue';

    export default {
        components: { MultiSelect, CreateEditBreedModal },

        data() {
            return {
                fractions: collect(),
                breeds: collect(),
                types: collect(),

                createEditFractionsModal: false

            }
        },

        methods: {

            openCreateBreedModal()
            {
                this.createEditFractionsModal = {
                    id: null,
                    fractionsIds: [],
                    type_id: null,
                    name: ''
                }
            },

            openEditBreedModal(breed) {
                this.createEditFractionsModal = {
                    id: breed.id,
                    typeId: breed.type.id,
                    fractionsIds: breed.fractions.pluck('id').all(),
                    name: breed.name
                }
            },

            getFractions() {
                fractionService.get().then(fractions => {
                    this.fractions = fractions;
                });
            },

            getBreeds() {
                breedService.get({include: ['fractions', 'type']}).then(breeds => {
                    this.breeds = breeds;
                });
            },

            getTypes() {
                typeService.get({filters: {0: 'hasOneChild'}}).then(types => {
                    this.types = types;
                });
            },

            breedStored() {
                this.getBreeds();
                this.createEditFractionsModal = false;
            },
        },

        created() {
            this.getFractions();
            this.getBreeds();
            this.getTypes();

            this.$emit('breadcrumbs', [
                {label: 'Instellingen', to: '/settings'},
                {label: 'Rassen'}
            ])
        }
    }
</script>
